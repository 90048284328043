










































@import '../../../styles/ondemand'
.q-card__section
  padding 0 0 0 20px
