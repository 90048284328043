.mobile .code {
  display: none;
}
.mobile .column.container {
  margin-top: -32px;
}
.mobile .cta {
  background-color: #fafafa;
  padding: 16px 8px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 5px 8px rgba(0,0,0,0.14), 0 1px 14px rgba(0,0,0,0.12);
}
.cta {
  padding: 8px;
}
.code {
  height: 25vh;
  width: 100%;
  font-size: 8vmax;
  color: rgba(255,255,255,0.7);
  overflow: hidden;
  background-image: url("~assets/backgrounds/bike.jpg");
  background-position: 0% 67%;
  background-repeat: no-repeat;
  background-size: 100%;
  text-overflow: clip;
}
.column.container {
  margin-top: -64px;
}
.layout-padding {
  max-width: 768px;
}
.q-card {
  width: 80vw;
  max-width: 600px;
}
@media screen and (max-width: 700px) {
  .q-card {
    width: 100vw;
  }
}
.q-card.summary {
  max-width: 100%;
}
.center {
  justify-content: center;
  display: flex;
}
.page {
  padding-top: 16px;
}
.q-banner {
  border-width: 1px;
  border-style: solid;
  margin: 15px 0;
}
.q-btn {
  margin: 15px 0;
  width: 100%;
}
.status-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 125%;
  text-align: center;
}
.status-details {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 5px;
  grid-template-columns: 125px auto;
  align-items: center;
}
.status-icon {
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 90%;
  width: 100%;
}
.bike-hire-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.bike-hire-card.results-card {
  grid-template-rows: 2fr repeat(2, 1fr);
  grid-template-areas: "logo bike-pic"   "loc-name bike-pic"   "loc-addr price";
  height: 200px;
  overflow: hidden;
}
.bike-hire-card.summary-card {
  grid-template-auto-rows: auto;
  grid-template-areas: "title title"   "logo bike-pic"   "loc-name bike-pic"   "loc-addr price";
}
.title {
  grid-area: title;
}
.bike-hire-logo {
  grid-area: logo;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
}
.bike-hire-loc-name {
  grid-area: loc-name;
}
.bike-hire-loc-name.loc-large {
  font-weight: 600;
  font-size: 120%;
}
.bike-hire-loc-addr {
  grid-area: loc-addr;
}
.bike-hire-loc-date {
  grid-area: loc-addr;
  align-self: start;
}
.bike-hire-pic {
  grid-area: bike-pic;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}
.bike-hire-price {
  grid-area: price;
  margin-left: 35%;
}
.bike-hire-price.summary {
  align-self: start;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 5px;
  grid-template-rows: repeat(3, 1fr);
}
/*# sourceMappingURL=src/pages/ondemand/bikehire/results.css.map */